<template>
  <div v-if="word" class="price">
    <div class="stk animate__animated animate__flipInX">
      <!-- <div class="stkBg"></div> -->
      <img src="./../assets/home-bg.png" alt="" />
      <img class="img2" src="./../assets/priceImg.png" alt="" />
      <div class="font_detail">
        <h2>{{ word.menu.titleTop2[language] }}</h2>
        <p class="jianjie">
          {{ word.menu.jieshao[language] }}
        </p>

        <div class="jiage" @click="goToPrice">
          {{ word.menu.Learn[language] }}
        </div>
      </div>
      <div class="container">
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </div>
    <div id="product-title" class="price-title">
      <div>{{ word.price.page_title[language] }}</div>
      <img src="./../assets/search.png" alt="" />
    </div>

    <div class="country">
      <h2 class="heading-medium">{{ word.price.search[language] }}</h2>
      <div class="selectBox">
        <div id="select" class="select" @click="onSelect">
          <div id="select-result">{{ smsKeyword }}</div>
          <div id="arrow"></div>
        </div>

        <div class="select-body" id="select-body">
          <input type="text" autocomplete="false" id="select-input" :placeholder="word.price.searchText[language]" v-model="selectValue" @input="onChangeSelect" />
          <div class="select-options">
            <div @click="choose(item)" id="option" class="option" v-for="(item, index) in countryList" :key="index">
              <span id="countryFlag" class="countryFlag">{{
                item.countryNumber
              }}</span>
              {{ item[key] }}
            </div>
          </div>
        </div>
      </div>

      <div class="price-box" v-if="smsCountry">
        <div class="dropdown-2">
          <h4 class="price-title-category">
            {{ word.price.channel[language] }}
          </h4>
          <h4 class="price-title-unit">{{ word.price.usd[language] }}</h4>
        </div>

        <div class="price-list-box">
          <div class="price-list-item">
            <div class="price-list-item-title">
              <div class="price-icon-box">
                <img src="../assets/sms1.svg" alt="" />
              </div>
              <h4 class="price-list-item-text">
                {{ word.price.message[language] }}
              </h4>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{ paddingRight: !smsCountry.OTP ? '50px' : '' }">
                <h4 class="price-whatsapp-item-text">
                  {{ word.price.opt_price[language] }}
                </h4>
                <p class="price-whatsapp-item-desc">
                  {{ word.menu.otpDesc[language] }}
                </p>
              </div>

              <h4 class="price-list-item-value" v-show="smsCountry.OTP && smsCountry.OTP != -1">
                {{ smsCountry.OTP }}
              </h4>
              <div class="loading" v-show="smsCountry.OTP == null">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <p class="price-list-item-value-empty" v-show="smsCountry.OTP == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{ paddingRight: !smsCountry.market ? '50px' : '' }">
                <h4 class="price-whatsapp-item-text">
                  {{ word.price.notifications_price[language] }}
                </h4>
                <p class="price-whatsapp-item-desc">
                  {{ word.menu.marketDesc[language] }}
                </p>
              </div>
              <h4 class="price-list-item-value" v-show="smsCountry.market && smsCountry.market != -1">
                {{ smsCountry.market }}
              </h4>
              <div class="loading" v-show="!smsCountry.market">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="price-list-item-value-empty" v-show="smsCountry.market == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
          </div>
        </div>

        <div class="price-list-box">
          <div class="price-list-item">
            <div class="price-list-item-title">
              <div class="price-icon-box">
                <img src="../assets/Whatsapp1.svg" alt="" />
              </div>
              <h4 class="price-list-item-text">WhatsApp</h4>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{
                  paddingRight: !whatsAppPrice.authentication ? '50px' : '',
                }">
                <h4 class="price-whatsapp-item-text">Authentication</h4>
                <p class="price-whatsapp-item-desc">
                  {{ word.price.Authentication[language] }}
                </p>
              </div>

              <h4 class="price-list-item-value" v-show="
                  whatsAppPrice.authentication &&
                  whatsAppPrice.authentication != -1
                ">
                {{ whatsAppPrice.authentication }}
              </h4>
              <div class="loading" v-show="!whatsAppPrice.authentication">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="price-list-item-value-empty" v-show="whatsAppPrice.authentication == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{
                  paddingRight: !whatsAppPrice.marketing ? '50px' : '',
                }">
                <h4 class="price-whatsapp-item-text">Marketing</h4>
                <p class="price-whatsapp-item-desc">
                  {{ word.price.Marketing[language] }}
                </p>
              </div>

              <h4 class="price-list-item-value" v-show="
                  whatsAppPrice.marketing && whatsAppPrice.marketing != -1
                ">
                {{ whatsAppPrice.marketing }}
              </h4>
              <div class="loading" v-show="!whatsAppPrice.marketing">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="price-list-item-value-empty" v-show="whatsAppPrice.marketing == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{ paddingRight: !whatsAppPrice.utility ? '50px' : '' }">
                <h4 class="price-whatsapp-item-text">Utility</h4>
                <p class="price-whatsapp-item-desc">
                  {{ word.price.Utility[language] }}
                </p>
              </div>

              <h4 class="price-list-item-value" v-show="whatsAppPrice.utility && whatsAppPrice.utility != -1">
                {{ whatsAppPrice.utility }}
              </h4>
              <div class="loading" v-show="!whatsAppPrice.utility">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="price-list-item-value-empty" v-show="whatsAppPrice.utility == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{ paddingRight: !whatsAppPrice.service ? '50px' : '' }">
                <h4 class="price-whatsapp-item-text">
                  Service
                  <span class="free_serve">
                    {{ word.price.freeServe[language] }}</span>
                </h4>

                <p class="price-whatsapp-item-desc">
                  {{ word.price.Service[language] }}
                </p>
              </div>

              <!-- <h4 class="price-list-item-value" v-show="whatsAppPrice.service && whatsAppPrice.service != -1">
                {{ whatsAppPrice.service }}
              </h4> -->
              <!-- <div class="loading" v-show="!whatsAppPrice.service">
                <div></div>
                <div></div>
                <div></div>
              </div> -->
              <p class="price-list-item-value-empty">
                -
              </p>
            </div>
          </div>
        </div>

        <div class="price-list-box">
          <div class="price-list-item">
            <div class="price-list-item-title">
              <div class="price-icon-box">
                <img src="../assets/voice1.svg" alt="" />
              </div>
              <h4 class="price-list-item-text">
                {{ word.price.Voice[language] }}
              </h4>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{ paddingRight: !voiceCountry.mobile ? '50px' : '' }">
                <h4 class="price-whatsapp-item-text">
                  {{ word.price.mobile[language] }}
                </h4>
                <p class="price-whatsapp-item-desc" v-if="voiceCountry.mobile_increment">
                  {{ word.price.increment[language] }}
                  {{ voiceCountry.mobile_increment }}
                </p>
              </div>
              <h4 class="price-list-item-value" v-show="voiceCountry.mobile && voiceCountry.mobile != -1">
                {{ voiceCountry.mobile }}
              </h4>
              <div class="loading" v-show="!voiceCountry.mobile">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="price-list-item-value-empty" v-show="voiceCountry.mobile == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
            <div class="price-whatsapp-item">
              <div class="price-whatsapp-item-title" :style="{ paddingRight: !voiceCountry.fix ? '50px' : '' }">
                <h4 class="price-whatsapp-item-text">
                  {{ word.price.fix[language] }}
                </h4>
                <p class="price-whatsapp-item-desc" v-if="voiceCountry.fix_increment">
                  {{ word.price.increment[language] }}
                  {{ voiceCountry.fix_increment }}
                </p>
              </div>

              <h4 class="price-list-item-value" v-show="voiceCountry.fix && voiceCountry.fix != -1">
                {{ voiceCountry.fix }}
              </h4>
              <div class="loading" v-show="!voiceCountry.fix">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p class="price-list-item-value-empty" v-show="voiceCountry.fix == -1">
                {{ word.price.supported[language] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/request/request.js";
import base from "@/utils/request/base.js";
import word from "./../assets/json/language.json";
import country from "./../assets/json/country.json";
import "animate.css";
export default {
  name: "Price",
  components: {},
  data() {
    return {
      selectList: [],
      selectValue: "",
      language: "",
      word: word,
      showSMSCountryList: false,
      showVoiceCountryList: false,
      countryList: [],
      country: {
        sms: [],
        voice: [],
      },
      isShow: false,
      key: "countryNameEn",
      smsKeyword: "",
      voiceKeyword: "",
      smsCountry: {},
      voiceCountry: {},
      whiteDom: ["select-input", "select-result", "arrow", "select"],
      whatsAppPrice: {},
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 可选的平滑滚动效果
    });
    document.addEventListener("click", (e) => {
      if (!this.whiteDom.includes(e.target.id)) {
        const element = document.getElementById("select-body");
        const element1 = document.getElementById("arrow");
        if (element) {
          element?.classList.remove("active");
        }

        element1?.classList.remove("rotate");
        this.isShow = false;
      }
    });
  },
  created() {
    this.language = localStorage.getItem("language");
    axios.get(`/global-price/getAllCountry`).then((res) => {
      this.selectList = res.data;
      this.country = res.data;
      res.data.countriesAll = Array.from(
        new Set(
          res.data.countriesAll.sort((a, b) => {
            if (this.language == "EN") {
              return a["countryNameEn"].localeCompare(b["countryNameEn"]);
            } else {
              return a.countryName > b.countryName;
            }
          })
        )
      );
      this.countryList = res.data.countriesAll;
      for (let item of this.country.countriesAll) {
        item.show = true;
      }
      this.key = this.language == "EN" ? "countryNameEn" : "countryName";
      this.smsKeyword =
        this.country.countriesAll[0].countryNumber > 0
          ? "(" +
          this.country.countriesAll[0].countryNumber +
          ") " +
          this.country.countriesAll[0][this.key]
          : this.country.countriesAll[0][this.key];
      this.smsCountry = this.country.countriesAll[0];
      this.getPrice(this.country.countriesAll[0], 1);
    });
  },

  methods: {
    goToPrice() {
      const element1 = document.getElementById("product-title");
      var offsetTop1 = element1?.offsetTop - 100;
      window.scrollTo({
        top: offsetTop1,
        behavior: "smooth", // 可选的平滑滚动效果
      });
      document.addEventListener("click", (e) => {
        if (!this.whiteDom.includes(e.target.id)) {
          const element = document.getElementById("select-body");
          const element1 = document.getElementById("arrow");
          element?.classList.remove("active");
          element1?.classList.remove("rotate");
          this.isShow = false;
        }
      });
    },
    onChangeSelect() {
      this.countryList = this.country.countriesAll;
      if (!this.selectValue || this.selectValue == "") {
        this.countryList = this.country.countriesAll;
      } else {
        this.countryList = this.selectList.countriesAll.filter((item) => {
          return (
            item.countryNameEn.includes(this.selectValue) ||
            item.countryNumber.includes(this.selectValue) ||
            item.countryName.includes(this.selectValue)
          );
        });
        this.countryList = Array.from(
          new Set(
            this.countryList.sort((a, b) => {
              if (this.language == "EN") {
                return a["countryNameEn"].localeCompare(b["countryNameEn"]);
              } else {
                return a.countryName > b.countryName;
              }
            })
          )
        );
      }
    },
    onSelect() {
      const element = document.getElementById("select-body");
      const element1 = document.getElementById("arrow");
      if (!this.isShow) {
        element?.classList.add("active");
        element1?.classList.add("rotate");
      } else {
        element?.classList.remove("active");
        element1?.classList.remove("rotate");
      }
      this.isShow = !this.isShow;
    },
    hiddenSelect() {
      setTimeout(() => {
        this.showSMSCountryList = false;
      }, 200);
    },
    hiddenVoiceSelect() {
      setTimeout(() => {
        this.showVoiceCountryList = false;
      }, 200);
    },
    getPrice(country, type) {
      let url = `/global-price/getAll?countryCode=` + country.countryCode;
      let arry = [];
      let arry1 = [];
      axios.get(url).then((res) => {
        for (let item of res.data) {
          if (item.productType == 1) {
            if (item.type == 1) {
              if (item.price) {
                this.smsCountry.OTP = item.price;
              } else {
                this.smsCountry.OTP = -1;
              }
            }
            if (item.type == 2) {
              if (item.price) {
                this.smsCountry.market = item.price;
              } else {
                this.smsCountry.market = -1;
              }
            }
          }
          if (item.productType == 2) {
            // 不区分
            if (item.type == 0) {
              this.voiceCountry.mobile = item.price ? item.price : -1;
              this.voiceCountry.fix = item.price ? item.price : -1;
              this.voiceCountry.mobile_increment = item.increment
                ? item.increment
                : "";
              this.voiceCountry.fix_increment = item.increment
                ? item.increment
                : "";
            }
            if (item.type == 1) {
              this.voiceCountry.fix = item.price ? item.price : -1;
              this.voiceCountry.fix_increment = item.increment
                ? item.increment
                : "";
            }
            if (item.type == 2) {
              this.voiceCountry.mobile = item.price ? item.price : -1;
              this.voiceCountry.mobile_increment = item.increment
                ? item.increment
                : "";
            }
          }

          if (item.productType == 3) {
            // 不区分
            if (item.type == 0) {
              this.whatsAppPrice.authentication = item.price ? item.price : -1;
            }
            if (item.type == 1) {
              this.whatsAppPrice.marketing = item.price ? item.price : -1;
            }
            if (item.type == 2) {
              this.whatsAppPrice.utility = item.price ? item.price : -1;
            }
            if (item.type == 3) {
              this.whatsAppPrice.service = item.price ? item.price : -1;
            }
          }
          arry.push(item.productType);
          arry1 = Array.from(new Set(arry));
        }
        if (arry1.indexOf("1") == -1) {
          this.smsCountry.OTP = -1;
          this.smsCountry.market = -1;
        }
        if (arry1.indexOf("2") == -1) {
          this.voiceCountry.mobile = -1;
          this.voiceCountry.fix = -1;
        }
        if (arry1.indexOf("3") == -1) {
          this.whatsAppPrice.authentication = -1;
          this.whatsAppPrice.marketing = -1;
          this.whatsAppPrice.utility = -1;
          this.whatsAppPrice.service = -1;
        }
      });
      // if(arry1.indexOf('1') > -1){

      // }
    },
    async choose(e) {
      this.smsKeyword =
        e.countryNumber > 0
          ? "(" + e.countryNumber + ") " + e[this.key]
          : e[this.key];
      this.smsCountry = e;
      e.show = true;
      this.getPrice(e, 1);
    },
    chooseVoice(e) {
      this.voiceKeyword =
        e.countryNumber > 0
          ? "(" + e.countryNumber + ") " + e[this.key]
          : e[this.key];
      this.voiceCountry = e;
      for (let item of this.country.voice) {
        item.show = true;
        if (e.countryNumber == item.countryNumber) {
          this.getPrice(item, 2);
        }
      }
    },
    searchCountry() {
      for (let item of this.country.sms) {
        item.show = false;
        if (
          item.CN.toUpperCase().indexOf(this.smsKeyword.toUpperCase()) > -1 ||
          item.EN.toUpperCase().indexOf(this.smsKeyword.toUpperCase()) > -1 ||
          (item.countryNumber > 0 &&
            item.countryNumber.toString().indexOf(this.smsKeyword) > -1)
        ) {
          item.show = true;
        }
      }
    },
    searchVoiceCountry() {
      for (let item of this.country.voice) {
        item.show = false;
        if (
          item.CN.toUpperCase().indexOf(this.voiceKeyword.toUpperCase()) > -1 ||
          item.EN.toUpperCase().indexOf(this.voiceKeyword.toUpperCase()) > -1 ||
          (item.countryNumber > 0 &&
            item.countryNumber.toString().indexOf(this.voiceKeyword) > -1)
        ) {
          item.show = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loading,
.loading > div {
  position: relative;
  box-sizing: border-box;
}

.loading {
  display: block;
  font-size: 0;
  color: #000;
}

.loading.la-dark {
  color: #333;
}

.loading > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.loading {
  width: 54px;
  height: 18px;
}

.loading > div {
  width: 7px;
  height: 7px;
  margin: 2px;
  border-radius: 100%;
  animation: ball-beat 0.7s -0.15s infinite linear;
}

.loading > div:nth-child(2n-1) {
  animation-delay: -0.5s;
}

@keyframes ball-beat {
  50% {
    opacity: 0.2;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.img2 {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 450px !important;
  height: 400px !important;
}
.stk {
  width: 100%;
  height: 100vh;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }

  .stkBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #424267 !important;
    opacity: 0.5 !important;
    z-index: 2;
  }

  .font_detail {
    width: 65%;
    font-size: 30px !important;
    color: #fff !important;
    line-height: 1.5em !important;
    font-weight: 800 !important;
    position: absolute;
    bottom: 130px;
    z-index: 33;
    left: 40px;
  }

  .jianjie {
    line-height: 1.5em !important;
    font-size: 18px;
    width: 60%;
    color: #fff !important;
  }

  .jiage {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    width: 150px;
    border: 2px solid #fff;
    cursor: pointer;

    &:hover {
      background: #108ccf;
    }
  }
}
.price-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: 50px auto;
  font-size: 48px;
  font-weight: 800;
  img {
    width: 40%;
  }
}
.search-input {
  height: 50px;
  padding: 10px 0;
  width: 35%;
  min-width: 450px;
  margin-bottom: 50px;
  position: relative;
  .country-list {
    position: absolute;
    top: 55px;
    left: 16px;
    max-height: 200px;
    padding: 0 0 2px;
    width: 100%;
    background-color: #fff;
    overflow: auto;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    div {
      cursor: pointer;
      padding: 5px;
      &:hover {
        background-color: #e2eff7;
      }
    }
  }
  input {
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    font-size: 18px;
    &:focus {
      outline: none;
      border: 1px solid #e2e2e2;
    }
  }
}

.heading-medium {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-bottom: 64px;
  padding-right: 200px;
  padding-left: 200px;
  line-height: 1.4;
  text-align: center;
  font-size: 38px;
  margin-bottom: 32px;
  position: relative;
}

.country {
  position: relative;
  z-index: 9;

  .selectBox {
    position: sticky !important;
    top: 100px;
  }
  .select {
    position: sticky;
    top: 0px;
    width: 420px;
    height: 52px;
    line-height: 48px;
    border: 2px solid #1a1e22;
    border-radius: 200px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 600;
    padding: 0 20px;
    cursor: pointer;
    overflow: hidden;
    word-break: break-all;
    background-color: #fff;
    margin: 0 auto;
  }

  #select {
    z-index: 999999;
  }

  #arrow {
    width: 9px;
    height: 9px;
    border-bottom: 2px solid #1a1e22;
    border-left: 2px solid #1a1e22;
    position: absolute;
    top: 19px;
    left: 390px;
    transform: rotate(-45deg);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
  }

  .select-body {
    z-index: 9999 !important;
    display: none;
    margin: 0 auto;
    background-color: #fff;
    border: 2px solid #1a1e22;
    border-radius: 16px;
    padding: 20px 25px;
    width: 420px;
    max-height: 420px;
    overflow-y: auto;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    #select-input {
      outline: none;
      width: 100%;
      height: 40px;
      border: 2px solid #1a1e22;
      font-size: 16px;
      padding: 0 20px;
      margin-bottom: 15px;
      box-sizing: border-box;
      line-height: normal;
    }
    .select-options {
      .option {
        font-size: 16px;
        font-weight: 600;
        padding: 5px 0;
        &:hover {
          color: #6236ff;
        }
        cursor: pointer;
        .countryFlag {
          display: inline-block;
          width: 50px;
        }
      }
    }
  }

  .select-body::-webkit-scrollbar {
    width: 7px; /* 对垂直滚动条有效 */
    // height: 10px;  /* 对水平滚动条有效 */
  }

  /* 定义滚动条的轨道颜色、内阴影及圆角 */
  .select-body::-webkit-scrollbar-track {
    display: none !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #ececec;
    border-radius: 5px;
  }

  /* 定义滑块颜色、内阴影及圆角 */
  .select-body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* 光标移到滚动滑块上样式颜色变深 */
  .select-body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  /* 定义右下角汇合处的样式 */
  .select-body::-webkit-scrollbar-corner {
    background: khaki;
  }
}

.active {
  display: block !important;
}
.rotate {
  transform: rotate(135deg) !important;
}

.price-box {
  border: 1px solid #1b1f23;
  border-radius: 16px;
  color: #000;
  width: 74%;
  margin: 30px auto 30px auto;

  .dropdown-2 {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    height: 80px;
    background-color: #f8f9fd;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    .price-title-category {
      width: 74%;
      padding-left: 51px;
      line-height: 24px;
    }
  }
}

.price-list-box {
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: 0 auto;

  .price-list-item {
    border-bottom: 1px solid #dfe3e8;
    padding-top: 11px;
    padding-bottom: 11px;

    .price-list-item-title {
      display: flex;
      align-items: center;
      width: 65%;
      padding-left: 32px;
      flex-shrink: 0;

      .price-icon-box {
        box-sizing: border-box;
        width: 56px;
        height: 56px;
        border-radius: 100%;
        text-align: center;
        padding-top: 12px;
        background-color: #fff6dc;
      }

      .price-list-item-text {
        font-size: 20px;
        margin-left: 24px;
      }
    }

    .price-whatsapp-item {
      padding-top: 8px;
      padding-bottom: 8px;
      border: 0;
      display: flex;
      align-items: center;

      .price-whatsapp-item-title {
        width: 62%;
        padding-left: 113px;
        padding-right: 70px;
        flex-shrink: 0;

        .price-whatsapp-item-text {
          margin: 0px;
          .free_serve {
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
            color: #11bb82;
            background: #e0f6ee;
            padding: 3px 11px;
            border-radius: 16px;
            margin-left: 8px;
            vertical-align: middle;
          }
        }

        .price-whatsapp-item-desc {
          font-size: 12px;
          margin-top: 8px;
          margin-bottom: 0;
          line-height: 1.6;
          font-weight: 400;
          text-align: left;
          color: #330;
        }
      }

      .price-list-item-value {
        font-size: 20px;
        flex-grow: 1;
        color: #4d3fe5;
        margin: 0;
      }

      .price-list-item-value-empty {
        font-size: 14px;
        color: #5c5f62;
        margin: 0;
        white-space: nowrap;
      }
    }
  }
}

.price-list {
  margin-bottom: 100px;
  background-color: #fbfbfb;
  padding: 40px 60px 80px;
  width: 70%;
  margin-left: 15%;
  > div {
    padding: 0 16px;
    .price-item-title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 60px;
      .subtitle {
        font-size: 14px;
        font-weight: normal;
        margin-top: 10px;
        color: #999;
      }
    }
    .price-item-list {
      width: 100%;
      display: flex;
      align-items: flex-end;
      > div {
        width: 30%;
        flex-shrink: 0;
        .price-item {
          margin-top: 20px;
          span {
            color: #108ccf;
            margin: 0 4px;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
